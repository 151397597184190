<template>
	<div
		:class="{ 'compact-mode': compactMode, 'micro-mode': microMode, 'hide-free-minutes': hideFreeMinutes }">
		<div class="advisor-chat-buttons" :class="{ 'pt-3 pb-4': !noPadding }" v-if="!isBlocked">
			<div v-if="largeSpecialOfferMode">
				<div v-if="freeMinutes || advisor.discount" class="p-5">
					<div class="card-subtitle line-on-side text-muted text-center mt-4 mx-4 font-weight-boold">
						<span>Special offer!</span>
					</div>

					<div class="text-center card-title pt-3">
						<span v-if="freeMinutes || advisor.discount">
							Let's <strong>Start</strong> with
							<strong v-if="freeMinutes">{{ freeMinutes }} FREE minutes </strong>
							<strong v-if="freeMinutes && advisor.discount"> &amp; </strong>
							<strong v-if="advisor.discount">{{ advisor.discount }}% OFF</strong>
						</span>
						<span v-else>&nbsp;</span>
					</div>
				</div>

				<div v-if="!(freeMinutes || advisor.discount)" class="text-center card-title font-large-1 pt-2"> </div>
			</div>

			<div v-show="advisor.status === 'online'" class="chat-buttons">
				<div class="no-padding-bottom" v-if="!isBlocked && cardUserIsAdvisor && !largeSpecialOfferMode">
					<p v-if="(freeMinutes || advisor.discount) && !microMode && !hideFreeMinutes" class="free-minutes">
						Let's <strong>Start</strong> with
						<strong v-if="freeMinutes">{{ freeMinutes }} FREE minutes </strong>
						<strong v-if="freeMinutes && advisor.discount"> &amp; </strong>
						<strong v-if="advisor.discount">{{ advisor.discount }}% OFF</strong>
					</p>
					<p v-else class="free-minutes">&nbsp;</p>
				</div>

				<button class="btn btn-primary btn-lg mr-1 centered" @click.prevent="forceStartChat(advisor, 'video', freeMode)"
					:disabled="!advisor.video_enabled || currentUser.id && !['user', 'admin', 'advisor'].includes(currentUser.role_name)">
					<font-awesome-icon icon="video" /> <span class="caption" v-if="!miniMode">Video Call</span>
					<div class="is-price" v-if="advisor.video_enabled">
						<s class="text-danger" v-if="advisor.discount">${{
							parseFloat(advisor.per_minute_fee_video).toFixed(2) }}</s>
						<br v-else />

						<b v-if="!freeMode">${{ calculateDiscountedFee(advisor.per_minute_fee_video, advisor) }}/min</b>
						<b v-else>[FREE]</b>
					</div>
					<div v-else-if="!microMode" class="is-offline mt-4">offline</div>
				</button>
				<button class="btn btn-primary btn-lg mr-1 centered" @click.prevent="forceStartChat(advisor, 'audio', freeMode)"
					:disabled="!advisor.audio_enabled || currentUser.id && !['user', 'admin', 'advisor'].includes(currentUser.role_name)">
					<font-awesome-icon icon="phone" /> <span class="caption" v-if="!miniMode">Voice Call</span>
					<div class="is-price" v-if="advisor.audio_enabled">
						<s class="text-danger" v-if="advisor.discount">${{
							parseFloat(advisor.per_minute_fee_audio).toFixed(2) }}</s>
						<br v-else />

						<b v-if="!freeMode">${{ calculateDiscountedFee(advisor.per_minute_fee_audio, advisor) }}/min</b>
						<b v-else>[FREE]</b>
					</div>
					<div v-else-if="!microMode" class="is-offline mt-4">offline</div>
				</button>
				<button class="btn btn-primary btn-lg mr-1 centered" @click.prevent="forceStartChat(advisor, 'text', freeMode)"
					:disabled="!advisor.text_enabled || currentUser.id && !['user', 'admin', 'advisor'].includes(currentUser.role_name)">
					<font-awesome-icon icon="comments" /> <span class="caption" v-if="!miniMode">Text Chat</span>
					<div class="is-price" v-if="advisor.text_enabled">
						<s class="text-danger" v-if="advisor.discount">${{
							parseFloat(advisor.per_minute_fee_text).toFixed(2) }}</s>
						<br v-else />

						<b v-if="!freeMode">${{ calculateDiscountedFee(advisor.per_minute_fee_text, advisor) }}/min</b>
						<b v-else>[FREE]</b>
					</div>
					<div v-else-if="!microMode" class="is-offline mt-4">offline</div>
				</button>
			</div>
			<div class="btn btn-primary btn-lg mr-1" @click.prevent="toChat"
				v-if="!(advisor.status === 'online') && currentUser.active_chat && currentUser.active_chat.advisor_id === advisor.id">
          <font-awesome-icon icon="video" v-if="currentUser.active_chat.mode === 'video'" />
          <font-awesome-icon icon="phone" v-if="currentUser.active_chat.mode === 'audio'" />
          <font-awesome-icon icon="comments" v-if="currentUser.active_chat.mode === 'text'" />
          <span class="caption">To chat</span>
			</div>
			<div class="d-flex align-items-center justify-content-start">
				<Link :href="`/advisor/${advisor.url}/subscribe`" class="btn btn-primary mr-2 mb-2" v-if="showMessageWithChatButtons && isUser && advisor.subscriptions_enabled && !isSubscribedToAdvisor(advisor)">
					<font-awesome-icon icon="user-check" /> Subscribe
				</Link>

				<Link :href="`/advisor/${advisor.url}`" disabled="disabled" class="btn btn-primary mr-2 mb-2" v-else-if="showMessageWithChatButtons && advisor.subscriptions_enabled && isSubscribedToAdvisor(advisor)">
					<font-awesome-icon icon="check" /> Subscribed
				</Link>

				<button class="btn btn-lg btn-warning mr-1" @click.prevent="forceOpenMessageModal(advisor)"
					v-if="(advisor.status !== 'online' || showMessageWithChatButtons) && !currentUser.active_chat">
					<font-awesome-icon icon="envelope" /> Message
				</button>

				<button class="btn btn-lg btn-info mr-1" @click.prevent="commitNotifyMe(advisor)"
					v-show="isUser && !isNotify(advisor.id) && advisor.status !== 'online' && showNotifyMeInCompactMode">
					<font-awesome-icon icon="bell" />
					Notify me
				</button>
				<button class="btn btn-lg btn-info mr-1" @click.prevent="confirmCancelNotification(advisor)"
					v-show="isUser && isNotify(advisor.id) && showNotifyMeInCompactMode">
					<font-awesome-icon icon="bell" />
					Cancel notify
				</button>
			</div>
		</div>
		<div v-else>Blocked</div>
		<div class="chat-buttons-bottom pb-3 d-flex align-items-center" v-if="!compactMode && cardUserIsAdvisor && !hideViewButtons">
			<button class="btn btn-link fs-6" @click.prevent="showReviews()">
			View feedback
			</button>
			<button class="btn btn-sm btn-primary" @click.prevent="commitNotifyMe(advisor)"
				v-show="isUser && !isNotify(advisor.id) && advisor.status !== 'online'">
				<font-awesome-icon icon="bell" />
				Notify me
			</button>
			<button class="btn btn-sm btn-primary" @click.prevent="confirmCancelNotification(advisor)"
				v-show="isUser && isNotify(advisor.id)">
				<font-awesome-icon icon="bell" />
				Cancel notify
			</button>
			<span class="mx-3 fs-6 btn-link">
			  View profile
			</span>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { usersStore } from '/src/store/users'
import { chatStore } from '/src/store/chat'
import { inboxStore } from '/src/store/inbox'
import _ from '/src/helpers/arrayHelpers'

import Link from '/src/components/Link.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faComments, faEnvelope, faBell, faVideo, faPhone, faUserCheck, faCheck } from '@fortawesome/free-solid-svg-icons'
library.add(faComments, faEnvelope, faBell, faVideo, faPhone, faUserCheck, faCheck)

export default {
	name: 'AdvisorChatButtons',
	props: {
		advisor: {
			required: true,
		},
		compactMode: {
			default: false,
			type: Boolean,
		},
		hideViewButtons: {
			default: false,
			type: Boolean,
		},
		miniMode: {
			default: false,
			type: Boolean,
		},
		noPadding: {
			default: false,
			type: Boolean,
		},
		largeSpecialOfferMode: {
			default: false,
			type: Boolean,
		},
		freeMode: {
			default: false,
			type: Boolean,
		},
		hideFreeMinutes: {
			default: false,
			type: Boolean,
		},
		microMode: {
			default: false,
			type: Boolean,
		},
		showNotifyMeInCompactMode: {
			default: false,
			type: Boolean,
		},
		showMessageWithChatButtons: {
			default: false,
			type: Boolean,
		},
	},
	components: {
		Link,
		FontAwesomeIcon,
	},
	data() {
		return {
		}
	},
	mounted() {
	},
	methods: {
		...mapActions(usersStore, [
			'setCancelNotifyConfirmation',
			'setNotifyMeAbout',
			'isBlockedByCurrentUser',
			'getFreeMinutes',
			'startShowFeedbacksModal',
			'isSubscribedToAdvisor',
		]),
		...mapActions(chatStore, [
			'forceStartChat',
		]),
		...mapActions(inboxStore, [
			'forceOpenMessageModal',
		]),
		confirmCancelNotification(advisor) {
			this.setCancelNotifyConfirmation(_.filter(this.currentUser.notify, item => item.advisor_id === advisor.id)[0])
		},
    toChat() {
      if(!(this.advisor.status === 'online') && this.currentUser.active_chat && this.currentUser.active_chat.advisor_id === this.advisor.id){
        window.open(`/dashboard/chat?mode=${this.currentUser.active_chat.mode}`, '_self');
      }
    },
		isNotify(id) {
			return _.filter(this.currentUser.notify, item => item?.advisor_id === id).length > 0
		},
		commitNotifyMe(user) {
			if (this.$auth?.check()) {
				this.setNotifyMeAbout(user)
			} else {
				this.$router.push({
					path: '/register',
					query: {
						redirect_url: this.$route.path,
						action: 'notifyMe',
						param: user.id
					}
				})
			}
		},
		showReviews() {
			this.startShowFeedbacksModal(this.advisor.id)
		},
	},
	computed: {
		...mapState(usersStore, [
			'currentUser',
			'isAdvisor',
			'isUser',
			'calculateDiscountedFee',
		]),
		freeMinutes() {
			return this.getFreeMinutes(this.advisor)
		},
		cardUserIsAdvisor() {
			return this.advisor.role_name == 'advisor'
		},
		isBlocked() {
			return this.isBlockedByCurrentUser(this.advisor.id) || this.advisor.deleted_at
		},
	}
}
</script>

<style lang="scss" scoped>
@import "/src/styles/variables";

.centered {
	margin: 0 auto !important;
}

.advisor-chat-buttons {
	flex: 1;

	.btn-sm {
		max-height: 21px;
	}

	&.stats-wrapper {
		@media screen and (max-width: 400px) {
			font-size: .9rem;
		}
	}
}

.advisor-chat-buttons {
	&.chat-buttons {
		padding: .3rem 1.5rem;

		&.no-padding-bottom {
			padding-bottom: 0;
		}

		&.no-padding-top {
			padding-top: 0;
		}
	}
}

.is-price {
	margin: 0;
}

.is-price .text-danger {
	display: block;
	color: #ffffff !important;
}



.text-center .chat-buttons button {
	margin-right: 5rem;
}

.text-center .chat-buttons button.btn-sm {
	padding-bottom: 0.3em !important;
	height: 26px;
	max-height: 26px;
}

button.btn-lg {
	margin-left: 0.25em !important;
	margin-right: 0.25em !important;
	padding-left: 0.5em !important;
	padding-right: 0.5em !important;
	text-align: center;
	font-size: 80%;
}

button.btn-lg svg {
	display: block;
	font-size: 200%;
	margin: 0.25em auto 0.5em auto;
}

button.btn-lg[disabled] {
	background-color: #dddddd !important;
	border: #dddddd !important;
	color: #111111 !important;
}

button.btn-lg[disabled] .is-offline {
	background-color: #ffffff !important;
	border-radius: 0.5rem;
}

.btn[disabled] svg {
	opacity: 0.5;
}

.chat-buttons-bottom {
	padding-bottom: .7rem;
	flex: 1;
	justify-content: space-around;
}

.text-center .buttons button {
	margin-right: 5rem;
}

.text-center .buttons button.btn-sm {
	padding-bottom: 0.3em !important;
	height: 26px;
	max-height: 26px;
}

/* compact mode styles */
.compact-mode .advisor-chat-buttons {
	padding-left: 0;
	padding-right: 0;
}

.compact-mode .btn-lg .caption {
	display: none;
}

.compact-mode .btn-lg {
	margin-left: 0 !important;
	margin-right: 0.5em !important;
	margin-bottom: 0.5em !important;
}

.compact-mode .btn-lg .is-price {
	display: inline-block;
}

.compact-mode .btn-lg .is-offline {
	display: inline-block;
}

.compact-mode .btn-lg .is-price s {}

.compact-mode .btn-lg svg {
	display: inline-block;
	margin: 0 0 0.5em 0;
	font-size: 150%;
}

.compact-mode .btn-lg.btn-warning svg,
.compact-mode .btn-lg.btn-info svg {
	margin: 0 0 0 0;
	vertical-align: top;
}

.free-minutes {
	margin-top: 0ex;
	margin-bottom: 1.75ex;
	color: var(--primary);
}

.micro-mode .is-price {
	display: none !important;
}

.micro-mode .btn-lg svg {
	margin: 0;
}

.micro-mode .advisor-chat-buttons {
	padding: 0 !important;
}

.compact-mode.hide-free-minutes .advisor-chat-buttons {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

.btn-link {
	color: var(--primary);
}

</style>
